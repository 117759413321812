import React from "react";
import ContentContainer from "../components/container/content-container";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import ColorDivider from "../components/color-divider/color-divider";
import { Link } from "gatsby";

const ThankYouPage: React.FC<{}> = () => (
  <PrimaryLayout>
    <SEO title="Thank you" />
    <ContentContainer width="100%">
      <div style={{ paddingTop: 150, paddingBottom: 150 }}>
        <h1 style={{ fontFamily: "Lora", textAlign: "center" }}>
          Thank you for contacting us!
        </h1>
        <h2 style={{ fontFamily: "Lora", textAlign: "center" }}>
          We'll get back to you as soon as possible.
        </h2>
        <div style={{ textAlign: "center" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              margin: "0 auto",
              fontSize: "24px",
              borderBottom: "1px solid #2f323a",
              fontFamily: "Montserrat",
            }}
          >
            Return home
          </Link>
        </div>
      </div>
      <ColorDivider color="cgMustard" />
    </ContentContainer>
  </PrimaryLayout>
);

export default ThankYouPage;
